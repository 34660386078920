import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import checkPrimaryLight from 'assets/img/animated-icons/check-primary-light.json';
import Lottie from 'lottie-react';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { Done as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import { useHistory } from 'react-router-dom';
import 'assets/scss/custom/done.scss';
import InfoPopover from 'components/utilities/InfoPopover';
import { VideoItem } from 'pages/Dashboard/Onboard/VideoCarousel';
import { breakpoints } from 'helpers/utils';

const Done = ({ createdBot, setCreatedBot, isPortfolio = false }) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const [playSrc, setPlaySrc] = useState(null);
  const isBotTask =
    createdBot &&
    Object.keys(createdBot).length > 0 &&
    Object.keys(createdBot).includes('bot_task');

  const queryParameters = new URLSearchParams(window.location.search);
  const exchangeUrl = queryParameters.get('exchange') || 'binance';
  const routerHistory = useHistory();
  const isMobile = window.innerWidth < breakpoints['sm'];

  const goBack = () => {
    queryParameters.delete('id');
    routerHistory.push(`?${queryParameters.toString()}`);
    setCreatedBot();
  };

  const showVideo = isPortfolio && exchangeUrl !== 'virtual';

  const data = {
    id: 'how_portfolio_works',
    src: `https://hodlie.ai/static/how_portfolio_works${
      isMobile ? '_small' : ''
    }.mp4`,
    title: 'title',
    time: '0:50',
    poster: 'video/poster/how_portfolio_works.png',
    horizontal: true
  };

  useEffect(() => {
    if (!document.title.endsWith(' - Started')) {
      // update page title
      document.title = document.title + ' - Started';
    }
  }, []);

  return (
    <>
      <Row
        className={`align-items-center mt-${showVideo ? '2' : '6'} mb-${
          showVideo ? '2' : '6'
        } gutter-x-0`}
        style={{ textAlign: 'center' }}
      >
        <Col>
          <Row className="align-items-center">
            <Col lg={12} className="d-flex justify-content-center">
              <Lottie
                animationData={checkPrimaryLight}
                loop={true}
                style={{
                  height: `${showVideo ? '60' : '120'}px`,
                  width: `${showVideo ? '60' : '120'}px`
                }}
              />
            </Col>
          </Row>
          <Flex justifyContent="center" className="text-center">
            <h2 className="fw-normal text-900 mt-2">
              {isPortfolio ? textLang.your[lang] : textLang.yourPersonal[lang]}
              <strong>{isPortfolio ? 'AI Portfolio' : createdBot.name}</strong>
              {!isBotTask ? textLang.running[lang] : textLang.near[lang]}
            </h2>
            {isBotTask && (
              <InfoPopover
                Text={textLang.soonText[lang]}
                className="d-flex align-items-center ms-2"
                classnameSize="fa-lg"
              />
            )}
          </Flex>
          <h4 className="fw-normal text-700 mt-2">
            {showVideo ? textLang.videoDescr[lang] : textLang.check[lang]}
          </h4>
        </Col>
      </Row>
      {showVideo && (
        <Row>
          <Col as={Flex} justifyContent="center">
            <VideoItem
              data={data}
              playSrc={playSrc}
              setPlaySrc={setPlaySrc}
              showDescription={false}
            />
          </Col>
        </Row>
      )}
      <Flex
        justifyContent={`${isPortfolio ? '' : 'lg-'}center`}
        className="mt-5"
      >
        {!isPortfolio && (
          <IconButton
            onClick={() => goBack()}
            variant="outline-secondary"
            className="me-2 mb-1 "
            icon="hammer"
            iconAlign="left"
            transform="shrink-3"
          >
            {textLang.newBot[lang]}
          </IconButton>
        )}
        {window.location.pathname === '/invest' && (
          <IconButton
            to={exchangeUrl === 'virtual' ? '/virtual-mode' : '/'}
            as={Link}
            variant="outline-secondary"
            className="me-2 mb-1 "
            icon="chart-pie"
            iconAlign="left"
            transform="shrink-3"
          >
            {exchangeUrl === 'virtual'
              ? textLang.walletVirtualLink[lang]
              : textLang.walletLink[lang]}
          </IconButton>
        )}
      </Flex>
    </>
  );
};

Done.propTypes = {
  createdBot: PropTypes.object.isRequired,
  setCreatedBot: PropTypes.func.isRequired,
  isPortfolio: PropTypes.bool
};
export default Done;
