import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import { breakpoints, getColor } from 'helpers/utils';
import { coinsData } from 'services/coins/common';
import dayjs from 'dayjs';
import classNames from 'classnames';

echarts.use([LineChart, TooltipComponent, GridComponent, LegendComponent]);

const Y_AXIS_INTERVAL = 100;

export const getChartMin = dataArrays => {
  let min = Math.max(0, Math.round(0.95 * Math.min(...dataArrays.flat())));
  min = min - (min % Y_AXIS_INTERVAL);
  return min;
};

export const getChartMax = dataArrays => {
  let max = Math.round(1.05 * Math.max(...dataArrays.flat()));
  max = max + (Y_AXIS_INTERVAL - (max % Y_AXIS_INTERVAL));
  return max;
};

const orderParams = array => {
  return array.sort((a, b) => {
    if (a.seriesName === 'HODLIE') return -1;
    if (b.seriesName === 'HODLIE') return 1;

    return b.value - a.value;
  });
};

const calcPerc = (start, end) => {
  if (start === 0) return 0;
  const grouth = ((end - start) / start) * 100;
  return grouth;
};

const tooltipFormatter = (params, data) => {
  let tooltipItem = ``;
  params = orderParams(params);
  const startValue = data.HODLIE.find(value => value != null);
  params.forEach(el => {
    tooltipItem =
      tooltipItem +
      `<h6 class="fs--1 text-700 d-flex align-items-center">
        <div class="dot me-2" style="background-color:${el.borderColor}"></div>
        ${coinsData[el.seriesName]?.name || 'Hodlie'} :  ${calcPerc(
        startValue,
        el.value
      ).toFixed(2)}%
      </h6>`;
  });
  return `<div class='ms-1'>
            ${tooltipItem}
          </div>`;
};

const getOption = (data, simplify, min, max) => {
  const coinNames = Object.keys(data).filter(key => key !== 'TIMESTAMP');

  // Put hodlie first
  const hodlieIndex = coinNames.indexOf('HODLIE');
  if (hodlieIndex > -1) {
    coinNames.splice(hodlieIndex, 1);
    coinNames.unshift('HODLIE');
  }

  const seriesData = coinNames.map(coin => ({
    name: coin,
    type: 'line',
    data: data[coin],
    lineStyle: {
      color: coinsData[coin]?.color || getColor('primary'),
      width: coin === 'HODLIE' ? 3 : 1,
      opacity: coin === 'HODLIE' ? 1 : 0.25
    },
    itemStyle: {
      borderColor: coinsData[coin]?.color || getColor('primary'),
      borderWidth: 2
    },
    symbol: 'none',
    smooth: false,
    emphasis: {
      scale: true
    }
  }));

  const dataArrays = coinNames.map(coin => data[coin]);

  return {
    title: {
      text: '',
      textStyle: {
        fontWeight: 500,
        fontSize: 13,
        fontFamily: 'poppins',
        color: getColor('800')
      }
    },
    color: getColor('white'),
    tooltip: {
      show: !simplify && window.innerWidth >= breakpoints['sm'],
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('100'),
      borderColor: getColor('300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: params => tooltipFormatter(params, data),
      transitionDuration: 0
    },
    legend: {
      show: false,
      data: ['HODLIE'],
      textStyle: { color: getColor('800') }
    },
    xAxis: {
      show: !simplify,
      type: 'category',
      data: data.TIMESTAMP,
      boundaryGap: false,
      axisPointer: {
        lineStyle: {
          color: getColor('300'),
          type: 'dashed'
        }
      },
      axisLine: {
        lineStyle: {
          color: getColor('300'),
          type: 'solid'
        }
      },
      axisTick: { show: false },
      axisLabel: {
        color: getColor('400'),
        margin: 15,
        formatter: value => dayjs(value).format('MMM DD')
      },
      splitLine: {
        show: !simplify,
        lineStyle: {
          color: getColor('300'),
          type: 'dashed'
        }
      }
    },
    yAxis: {
      type: 'value',
      axisPointer: { show: false },
      splitLine: {
        show: !simplify,
        lineStyle: {
          color: getColor('300')
        }
      },
      min: min != null ? min : getChartMin(dataArrays),
      max: max != null ? max : getChartMax(dataArrays),
      boundaryGap: false,
      axisLabel: {
        show: !simplify,
        color: getColor('400'),
        margin: 5
      },
      axisTick: { show: false },
      axisLine: { show: false }
    },
    series: seriesData,
    grid: simplify
      ? { right: 0, left: 0, bottom: 0, top: 0 }
      : { right: '8px', left: '50px', bottom: '15%', top: '5%' }
  };
};

const BacktestChart = forwardRef(
  ({ data, selectedYear, showAll = false, ...rest }, ref) => {
    const { max = null, min = null, simplify = false } = rest;

    return (
      <div
        className={classNames('', {
          'pointer-cursor-chart': simplify
        })}
      >
        <ReactEChartsCore
          echarts={echarts}
          ref={ref}
          option={getOption(
            selectedYear ? data[showAll ? 'ALL' : selectedYear] : data,
            simplify,
            min,
            max
          )}
          {...rest}
        />
      </div>
    );
  }
);

BacktestChart.propTypes = {
  data: PropTypes.object.isRequired,
  currency: PropTypes.string,
  selectedYear: PropTypes.string,
  showAll: PropTypes.bool
};

export default BacktestChart;
